import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  getDiscountPrice,
  getVariantWeightPrice,
  isPreOrderProduct,
} from "../../../helpers/product";
import { useTranslation } from "react-i18next";
import urls from "../../../urls";
import { formatPrice, formatWeight } from "../../../helpers/formatters";
import { CURRENCY } from "../../../constants/DeliveryConstants";
import { PRODUCT_PLACEHOLDER_SRC } from "../../../constants/ProductConstants";
import CtaLink from "../../cta/CtaLink";
import { colors } from "../../../utils/theme";
import { setProductAnalyticsEvent } from "../../../helpers/analytics";
import { ANALYTICS_REMOVE_FROM_CART } from "../../../constants/AnalyticsConstants";
import LazyImage from "../../image/LazyImage";
import { useCart } from "../../hooks/firestore/useCart";
import { useUserProfile } from "../../hooks/firestore/useUserProfile";
import { trackProductRemoved } from "../../../helpers/posthogAnalytics";

const MenuCart = ({ reference }) => {
  let cartTotalPrice = 0;
  const { t } = useTranslation();
  const { data: cart, deleteProductFromCart } = useCart();
  const cartItems = !!cart && !!cart.products ? cart.products : [];
  const { data: profile, profileLoading } = useUserProfile();
  const hidePrices = !!((!!profile && profile.hidePrices) || profileLoading);

  return (
    <div className="shopping-cart-content" ref={reference}>
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((single, key) => {
              const price = getVariantWeightPrice(single);
              const discountedPrice = getDiscountPrice(price, single.discount);
              const isPreOrder = isPreOrderProduct(
                single.pre_order,
                single.stock,
                single.orderUnit,
              );

              discountedPrice != null
                ? (cartTotalPrice += discountedPrice * single.quantity)
                : (cartTotalPrice += price * single.quantity);

              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={urls.product + single.url}>
                      <LazyImage
                        alt={single.name}
                        src={
                          !!single.image
                            ? single.image[0]
                            : PRODUCT_PLACEHOLDER_SRC
                        }
                        className="img-fluid"
                        rounded
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={urls.product + single.url}>
                        {" "}
                        {single.name}{" "}
                      </Link>
                    </h4>
                    <h6>
                      {t("quantity")}: {single.quantity}
                    </h6>
                    {single.variation && single.variation.weight && (
                      <h6>
                        {t("weight")}: {formatWeight(single.variation.weight)}
                        {t("gram_unit")}
                      </h6>
                    )}
                    {single.type && (
                      <h6>
                        {t("type")}: {single.type}
                      </h6>
                    )}
                    {isPreOrder && (
                      <h6 style={{ color: colors.pre_order }}>
                        {t("pre_order")}
                      </h6>
                    )}
                    {!hidePrices && (
                      <span>
                        {discountedPrice !== null
                          ? CURRENCY + formatPrice(discountedPrice)
                          : CURRENCY + formatPrice(price)}
                      </span>
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => {
                        deleteProductFromCart({
                          product: single,
                        });
                        setProductAnalyticsEvent(
                          ANALYTICS_REMOVE_FROM_CART,
                          single,
                          single.quantity,
                        );
                        trackProductRemoved(single, single.quantity, cart);
                      }}
                    >
                      <i
                        className="fa fa-times-circle"
                        id={"delete-cart-item"}
                      />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          {!hidePrices && (
            <div className="shopping-cart-total">
              <h4>
                {t("total")}:{" "}
                <span className="shop-total">
                  {CURRENCY + formatPrice(cartTotalPrice)}
                </span>
              </h4>
            </div>
          )}
          <CtaLink to={urls.cart} block className={"mb-3"}>
            {t("view_cart")}
          </CtaLink>
          <CtaLink to={urls.checkout} block className={"mb-2"}>
            {t("checkout")}
          </CtaLink>
        </Fragment>
      ) : (
        <p className="text-center">{t("cart_empty")}</p>
      )}
    </div>
  );
};

export default MenuCart;
