import posthog from "posthog-js";

// Track whether PostHog has been initialized
let isInitialized = false;

/**
 * Initialize PostHog with the provided API key and options
 */
export const initializePostHog = () => {
  if (isInitialized) return;

  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      persistence: "localStorage+cookie",
    });

    isInitialized = true;
    return posthog;
  }

  return null;
};

/**
 * Get the PostHog client if initialized, or null if not
 */
export const getPostHogClient = () => {
  if (!isInitialized) {
    // Check if consent was previously given
    const consentStatus = localStorage.getItem("consentGranted");

    // If consent was given previously, initialize PostHog
    if (consentStatus === "true") {
      return initializePostHog();
    }

    return null;
  }

  return posthog;
};

/**
 * Check if consent has been given and PostHog is available
 */
export function hasTracking() {
  return isInitialized || localStorage.getItem("consentGranted") === "true";
}

/**
 * Reset PostHog tracking state when consent is withdrawn
 */
export const resetPostHog = () => {
  if (!isInitialized) return;

  // Clear any existing PostHog data
  posthog.reset();
  isInitialized = false;
};
