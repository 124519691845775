export const ANALYTICS_PURCHASE = "purchase";
export const ANALYTICS_ADD_TO_CART = "add_to_cart";
export const ANALYTICS_REMOVE_FROM_CART = "remove_from_cart";
export const ANALYTICS_VIEW_ITEM = "view_item";
export const ANALYTICS_SELECT_ITEM = "select_item";

export const RELATED_PRODUCTS_LIST_ID = "related_products";
export const RELATED_PRODUCTS_LIST_NAME = "Related products";

// Event Names
export const POSTHOG_ANALYTICS_PRODUCT_VIEWED = "Product Viewed";
export const POSTHOG_ANALYTICS_PRODUCT_ADDED = "Product Added";
export const POSTHOG_ANALYTICS_PRODUCT_REMOVED = "Product Removed";
export const POSTHOG_ANALYTICS_CART_VIEWED = "Cart Viewed";
export const POSTHOG_ANALYTICS_CHECKOUT_STARTED = "Checkout Started";
export const POSTHOG_ANALYTICS_ORDER_COMPLETED = "Order Completed";
export const POSTHOG_ANALYTICS_PRODUCT_CLICKED = "Product Clicked";

// Brand Information
export const POSTHOG_ANALYTICS_BRAND = "Bombyxx";
export const POSTHOG_ANALYTICS_AFFILIATION = "Bombyxx Webshop";

// Currency
export const POSTHOG_ANALYTICS_CURRENCY = "eur";

// URL Base
export const POSTHOG_ANALYTICS_URL_BASE = process.env.REACT_APP_WEBSITE_URL;
