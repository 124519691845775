// src/helpers/posthogAnalytics.js

/**
 * PostHog Ecommerce Analytics Implementation
 * Follows PostHog's ecommerce events specification
 */

import { getPostHogClient } from "./posthogManager";
import { formatVariationToTitle } from "./formatters";
import { getVariantWeightPrice } from "./product";
import {
  POSTHOG_ANALYTICS_AFFILIATION,
  POSTHOG_ANALYTICS_BRAND,
  POSTHOG_ANALYTICS_CART_VIEWED,
  POSTHOG_ANALYTICS_CHECKOUT_STARTED,
  POSTHOG_ANALYTICS_CURRENCY,
  POSTHOG_ANALYTICS_ORDER_COMPLETED,
  POSTHOG_ANALYTICS_PRODUCT_ADDED,
  POSTHOG_ANALYTICS_PRODUCT_CLICKED,
  POSTHOG_ANALYTICS_PRODUCT_REMOVED,
  POSTHOG_ANALYTICS_PRODUCT_VIEWED,
  POSTHOG_ANALYTICS_URL_BASE,
} from "../constants/AnalyticsConstants";

/**
 * Get the cart document ID from the current cart object
 * Uses the document ID from Firestore if available
 * @param {Object} cart - The cart object from useCart() hook
 * @returns {String} Cart ID or null if not available
 */
export const getCartId = (cart) => {
  if (cart && cart.id) {
    return cart.id; // Use the Firestore document ID
  }
  return null;
};

/**
 * Track when a product is viewed
 * @param {Object} product - Product data
 */
export const trackProductViewed = (product) => {
  const posthogClient = getPostHogClient();
  if (!posthogClient) return;

  posthogClient.capture(POSTHOG_ANALYTICS_PRODUCT_VIEWED, {
    product_id: product.id,
    sku: product.code,
    category: Array.isArray(product.category)
      ? product.category[0]
      : product.category,
    name: product.name,
    brand: POSTHOG_ANALYTICS_BRAND,
    variant: product.variation
      ? formatVariationToTitle(product.variation)
      : null,
    price: product.price,
    quantity: 1,
    currency: POSTHOG_ANALYTICS_CURRENCY,
    url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${product.url}`,
    image_url: product.image ? product.image[0] : null,
  });
};

/**
 * Track when a product is added to cart
 * @param {Object} product - Product data
 * @param {Number} quantity - Quantity added
 * @param {Object} cart - The cart object from useCart() hook
 */
export const trackProductAdded = (
  product,
  quantity = 1,
  cart = null,
  context = {},
) => {
  const posthogClient = getPostHogClient();
  if (!posthogClient) return;

  posthogClient.capture(POSTHOG_ANALYTICS_PRODUCT_ADDED, {
    cart_id: getCartId(cart),
    product_id: product.id,
    sku: product.code,
    category: Array.isArray(product.category)
      ? product.category[0]
      : product.category,
    name: product.name,
    brand: POSTHOG_ANALYTICS_BRAND,
    variant: product.variation
      ? formatVariationToTitle(product.variation)
      : null,
    price: getVariantWeightPrice(product),
    quantity: quantity,
    url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${product.url}`,
    image_url: product.image ? product.image[0] : null,
    ...context,
  });
};

/**
 * Track when a product is removed from cart
 * @param {Object} product - Product data
 * @param {Number} quantity - Quantity removed
 * @param {Object} cart - The cart object from useCart() hook
 */
export const trackProductRemoved = (product, quantity = 1, cart = null) => {
  const posthogClient = getPostHogClient();
  if (!posthogClient) return;

  posthogClient.capture(POSTHOG_ANALYTICS_PRODUCT_REMOVED, {
    cart_id: getCartId(cart),
    product_id: product.id,
    sku: product.code,
    category: Array.isArray(product.category)
      ? product.category[0]
      : product.category,
    name: product.name,
    brand: POSTHOG_ANALYTICS_BRAND,
    variant: product.variation
      ? formatVariationToTitle(product.variation)
      : null,
    price: getVariantWeightPrice(product),
    quantity: quantity,
    url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${product.url}`,
    image_url: product.image ? product.image[0] : null,
  });
};

/**
 * Track when the cart is viewed
 * @param {Array} cartItems - Cart items
 * @param {Object} cart - The cart object from useCart() hook
 */
export const trackCartViewed = (cartItems, cart) => {
  const posthogClient = getPostHogClient();
  if (!posthogClient || !cartItems || cartItems.length === 0) return;

  const products = cartItems.map((item) => ({
    product_id: item.id,
    sku: item.code,
    category: Array.isArray(item.category) ? item.category[0] : item.category,
    name: item.name,
    brand: POSTHOG_ANALYTICS_BRAND,
    variant: item.variation ? formatVariationToTitle(item.variation) : null,
    price: getVariantWeightPrice(item),
    quantity: item.quantity,
    url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${item.url}`,
    image_url: item.image ? item.image[0] : null,
  }));

  posthogClient.capture(POSTHOG_ANALYTICS_CART_VIEWED, {
    cart_id: getCartId(cart),
    products: products,
  });
};

/**
 * Track when checkout is started
 * @param {Array} cartItems - Cart items
 * @param {Number} cartTotal - Cart total
 * @param {Object} shippingInfo - Shipping information
 */
export const trackCheckoutStarted = (
  cartItems,
  cartTotal,
  shippingInfo = {},
  cart = null,
) => {
  const posthogClient = getPostHogClient();
  if (!posthogClient || !cartItems || cartItems.length === 0) return;

  const products = cartItems.map((item) => ({
    product_id: item.id,
    sku: item.code,
    category: Array.isArray(item.category) ? item.category[0] : item.category,
    name: item.name,
    brand: POSTHOG_ANALYTICS_BRAND,
    variant: item.variation ? formatVariationToTitle(item.variation) : null,
    price: getVariantWeightPrice(item),
    quantity: item.quantity,
    url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${item.url}`,
    image_url: item.image ? item.image[0] : null,
  }));

  posthogClient.capture(POSTHOG_ANALYTICS_CHECKOUT_STARTED, {
    order_id: shippingInfo.orderId,
    cart_id: getCartId(cart),
    affiliation: POSTHOG_ANALYTICS_AFFILIATION,
    value: cartTotal,
    revenue: cartTotal - (shippingInfo.shipping || 0),
    shipping: shippingInfo.shipping || 0,
    tax: shippingInfo.tax || 0,
    discount: shippingInfo.discount || 0,
    coupon: shippingInfo.coupon || null,
    currency: POSTHOG_ANALYTICS_CURRENCY,
    products: products,
  });
};

/**
 * Track when an order is completed
 * @param {Object} order - Order data
 */
export const trackOrderCompleted = (order) => {
  const posthogClient = getPostHogClient();
  if (!posthogClient || !order) return;

  const products = order.products.map((product) => ({
    product_id: product.productId,
    sku: product.code,
    category: Array.isArray(product.category)
      ? product.category[0]
      : product.category,
    name: product.name,
    brand: POSTHOG_ANALYTICS_BRAND,
    variant: product.variant || null,
    price: product.originalPrice,
    quantity: product.quantity,
    url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${
      product.url || product.productId
    }`,
    image_url: product.image ? product.image[0] : null,
  }));

  posthogClient.capture(POSTHOG_ANALYTICS_ORDER_COMPLETED, {
    checkout_id: order.checkout_id || order.orderId,
    order_id: order.orderId,
    affiliation: POSTHOG_ANALYTICS_AFFILIATION,
    total: order.totalPrice,
    subtotal: order.totalPrice - (order.delivery?.cost || 0),
    revenue: order.totalPrice,
    shipping: order.delivery?.cost || 0,
    tax: order.vat || 0,
    discount: order.discount?.totalDiscount || 0,
    coupon: order.discount?.code || null,
    currency: POSTHOG_ANALYTICS_CURRENCY,
    products: products,
  });
};

/**
 * Triggers Product Clicked event in PostHog when clicking on a related product
 **/
export const trackRelatedProductClicked = (product, index, context = {}) => {
  const posthogClient = getPostHogClient();
  if (posthogClient) {
    posthogClient.capture(POSTHOG_ANALYTICS_PRODUCT_CLICKED, {
      product_id: product.id,
      sku: product.code,
      category: Array.isArray(product.category)
        ? product.category[0]
        : product.category,
      name: product.name,
      brand: POSTHOG_ANALYTICS_BRAND,
      variant: product.variation
        ? formatVariationToTitle(product.variation)
        : null,
      price: product.price,
      quantity: 1,
      position: index,
      url: `${POSTHOG_ANALYTICS_URL_BASE}/product/${product.url}`,
      image_url: product.image ? product.image[0] : null,
      ...context,
    });
  }
};
